import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Find Your Idea Case Studies";
export const _frontmatter = {};
const layoutProps = {
  title,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "case-studies"
    }}>{`Case Studies`}</h1>
    <p>{`Here you'll find a few case studies of my own projects. Both monetized and not. A few successes, some failures. With numbers when available.`}</p>
    <p>{`Hopefully they encourage you to get started and keep going :)`}</p>
    <p>{`More coming soon, check back.`}</p>
    <h2 {...{
      "id": "automating-love-notes"
    }}>{`Automating love notes`}</h2>
    <p>{`A fun project from a smol idea that creates a good story.`}</p>
    <Vimeo id="556273566" mdxType="Vimeo" />
    <br />
    <p>{`You can read more technical details here:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://swizec.com/blog/reverse-engineer-a-graphql-api-to-automate-love-notes-codewithswiz-24/"
        }}>{`Reverse engineer a GraphQL API to automate love notes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://swizec.com/blog/send-daily-random-photos-from-an-s3-bucket-using-aws-lambda-codewithswiz-25/"
        }}>{`Send daily random photos from an S3 bucket using AWS Lambda`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://swizec.com/blog/serverless-file-upload-to-s3-with-nextjs-and-aws-lambda-codewithswiz-27/"
        }}>{`Serverless file upload to S3 with NextJS and AWS Lambda`}</a></li>
    </ul>
    <h2 {...{
      "id": "why-programmers-work-at-night"
    }}>{`Why programmers work at night`}</h2>
    <p>{`A self published book from a viral essay.`}</p>
    <Vimeo id="556712384" mdxType="Vimeo" />
    <br />
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/eaa47726908df258d52bb63d11278999/7a18f/nightowls.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.3677130044843%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'265\\'%20viewBox=\\'0%200%20400%20265\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M359%20184v64h2c2%200%202%200%201-1-2%200-2-4-2-63l-1-63v63\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/eaa47726908df258d52bb63d11278999/ca0a1/nightowls.webp 223w", "/static/eaa47726908df258d52bb63d11278999/75680/nightowls.webp 445w", "/static/eaa47726908df258d52bb63d11278999/8d1ba/nightowls.webp 890w", "/static/eaa47726908df258d52bb63d11278999/26255/nightowls.webp 1284w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/eaa47726908df258d52bb63d11278999/e92b6/nightowls.png 223w", "/static/eaa47726908df258d52bb63d11278999/e66bf/nightowls.png 445w", "/static/eaa47726908df258d52bb63d11278999/4ef49/nightowls.png 890w", "/static/eaa47726908df258d52bb63d11278999/7a18f/nightowls.png 1284w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/eaa47726908df258d52bb63d11278999/4ef49/nightowls.png",
                "alt": "Screenshot of lifetime royalties",
                "title": "Screenshot of lifetime royalties",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Screenshot of lifetime royalties`}</figcaption>{`
  `}</figure></p>
    <p>{`The landing page is still live 👉 `}<a parentName="p" {...{
        "href": "https://nightowlsbook.com"
      }}>{`https://nightowlsbook.com`}</a></p>
    <h2 {...{
      "id": "useauth"
    }}>{`useAuth`}</h2>
    <p>{`An open source library from a cumbersome process.`}</p>
    <Vimeo id="556718779" mdxType="Vimeo" />
    <br />
    <p>{`You can try it out here 👉 `}<a parentName="p" {...{
        "href": "https://useauth.dev"
      }}>{`https://useauth.dev`}</a></p>
    <h2 {...{
      "id": "data-visualization-with-d3js"
    }}>{`Data Visualization with D3.js`}</h2>
    <p>{`A published best seller from a cold outreach email.`}</p>
    <Vimeo id="556969027" mdxType="Vimeo" />
    <br />
    <p>{`You can read more details about this one from articles I wrote at the time:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://swizec.com/blog/i-published-a-book-with-a-publisher-heres-what-the-journey-was-like/"
        }}>{`I published a book with a publisher. Here's what the journey was like.`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://swizec.com/blog/with-90k-i-could-become-a-fulltime-author/"
        }}>{`With 90k I could become a fulltime author`}</a></li>
    </ul>
    <lite-youtube {...{
      "videoid": "w1L2SgQuv6Q",
      "videostartat": "0"
    }}></lite-youtube>
    <br />
    <h2 {...{
      "id": "learn-while-you-poop"
    }}>{`Learn While you Poop`}</h2>
    <p>{`A video series that people remember 3 years later.`}</p>
    <Vimeo id="556979151" mdxType="Vimeo" />
    <br />
    <p>{`You can see the original videos here`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=JnMXuWy2t8A&list=PLF8WgaD4xmjWuh7FTYTealxehOuNor_2S"
          }}>{`Series 1 – 29 videos`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=BtOu6CiEz8M&list=PLF8WgaD4xmjUwRQMfDtGjJ1_UhOVMTjR9"
          }}>{`Series – 26 videos`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "react-for-dataviz"
    }}>{`React for DataViz`}</h2>
    <p>{`A profitable business from a friend's challenge.`}</p>
    <h2 {...{
      "id": "serverless-handbook"
    }}>{`Serverless Handbook`}</h2>
    <p>{`A self-published Amazon bestseller from a painful experience`}</p>
    <h2 {...{
      "id": "serverless-react"
    }}>{`Serverless React`}</h2>
    <p>{`A profitable course from a sold-out workshop.`}</p>
    <h2 {...{
      "id": "framework-for-modern-webapps"
    }}>{`Framework for modern webapps`}</h2>
    <p>{`A rejected idea because I lacked the appetite.`}</p>
    <h2 {...{
      "id": "send-text-to-open-door"
    }}>{`Send text to open door`}</h2>
    <p>{`A rejected idea from a poor prototype.`}</p>
    <h2 {...{
      "id": "techletterapp"
    }}>{`TechLetterApp`}</h2>
    <p>{`An internal tool that never became a product.`}</p>
    <h2 {...{
      "id": "senior-mindset"
    }}>{`Senior Mindset`}</h2>
    <p>{`An evergreen newsletter growing on its own.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      